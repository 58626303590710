const isDesktop = window.outerWidth > 1100;
const isMobile = window.outerWidth < 700;

// Check if one of the elems is on current page
const arrowElems = document.querySelectorAll('.js-arrow');
if (document.body.contains(document.querySelector('.js-arrow')) && isDesktop) {
  for (let i = 0; i < arrowElems.length; i++) {
    const elem = arrowElems[i];

    const oldURL = elem.querySelector('use').getAttribute('xlink:href')

    elem.addEventListener('mouseleave', () => {
      elem.querySelector('use').setAttribute('xlink:href', oldURL);
    });

    elem.addEventListener('mouseenter', () => {
      elem.querySelector('use').setAttribute('xlink:href', `/wp-content/themes/busbaan/public/icons.svg#${elem.dataset.icon}`);
    });
  }
}

const iconElems = document.querySelectorAll('.js-change-icon');
if (document.body.contains(document.querySelector('.js-change-icon')) && isMobile) {
  for (let i = 0; i < iconElems.length; i++) {
    const elem = iconElems[i];

    elem.parentNode.classList.add('cta__btn--mobile');
    elem.querySelector('use').setAttribute('xlink:href', `${elem.querySelector('use').getAttribute('xlink:href')}-mobile`);
  }
}
