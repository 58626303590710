import FormValidation from './inc/FormValidation';

// Check if submit element is on current page
const submitElem = document.querySelector('.js-submit-form');
if (document.body.contains(submitElem)) {
  const formElem = document.querySelector('.form');
  const loaderElem = formElem.querySelector('.form__loader');
  const messageElem = formElem.querySelector('.form__message');
  const inputElems = formElem.querySelectorAll('input[type="text"], input[type="email"], input[type="tel"], select, textarea');
  const hiddenElems = formElem.querySelectorAll('input[type="hidden"]');

  new FormValidation(formElem, inputElems);

  // Listen to sending the form, after validated as above
  formElem.addEventListener('submit', (ev) => {
    ev.preventDefault();

    const request = new XMLHttpRequest();
    request.open('POST', '/wp-content/themes/busbaan/inc/ajax/PostRequest.php', true);

    const formData = new FormData();
    for (let i = 0; i < inputElems.length; i++) {
      const input = inputElems[i];
      const id = input.getAttribute('id');

      if (id) {
        formData.append(`${id}`, input.value);
      }
    }

    for (let h = 0; h < hiddenElems.length; h++) {
      const hiddenInput = hiddenElems[h];
      const id = hiddenInput.getAttribute('id');

      if (id) {
        formData.append(`${id}`, hiddenInput.value);
      }
    }

    // for (var value of formData.values()) {
    //   console.log('value', value);
    // }

    request.send(formData);

    request.onreadystatechange = () => {
      loaderElem.classList.add('form__loader--active');

      if (request.readyState === 4) {
// const response = JSON.parse(request.responseText); // uitzetten anders crasht IE11
        loaderElem.classList.remove('form__loader--active');

        if (request.status === 200 && request.statusText === 'OK') {
          messageElem.classList.add('form__message--active');
          messageElem.innerHTML = 'Bedankt voor het invullen van het formulier. <br /> We nemen z.s.m. contact met je op.';

          // console.info('success', request.responseText);
          // console.info('success', response);
        } else {
          messageElem.classList.add('form__message--active');
          messageElem.innerHTML = 'Er is iets mis gegaan met het versturen van het formulier.';

          // console.error('error', response);
        }
      }
    }
  });
}
