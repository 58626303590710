const loadMoreBtn = document.querySelector('.js-load-more');

if (document.body.contains(loadMoreBtn)) {
  const referenceWrapper = document.querySelector('.js-append-references');
  let pageNumb = 1;

  loadMoreBtn.addEventListener('click', (ev) => {
    ev.preventDefault();
    loadMoreBtn.setAttribute('disabled', 'disabled');

    pageNumb++;

    const request = new XMLHttpRequest();
    request.open('POST', '/wp-content/themes/busbaan/inc/ajax/LoadPosts.php', true);

    request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    const formData = {
      pageNumber: pageNumb,
      ppp: loadMoreBtn.dataset.ppp,
      excludePost: loadMoreBtn.dataset.exclude
    }

    request.send(JSON.stringify(formData));

    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        const response = JSON.parse(request.responseText); // uitzetten anders crasht IE11

        if (request.status === 200 && request.statusText === 'OK') {
          //console.log( response );

          if (response && response.length > 0) {
            loadMoreBtn.removeAttribute('disabled');

            const appendElem = document.createElement('div');
            appendElem.innerHTML = response;

            referenceWrapper.appendChild(appendElem);
          }
        } else {
          console.error('error', response);
        }
      }
    };
  });
}